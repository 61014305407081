@import "src/styles/_variables.scss";

/* MAIN MENU */

.nav-menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: flex-end;

    .navbar-nav {
        margin-left: 15px;
        flex-direction: row;

        .nav-item {

            .menu {
                margin-right: 10px;
                margin-left: 10px;

                .nav-link {
                    padding: 0 0 10px 0;
                    display: flex;
                    align-items: center;
                    font-size: 17px;
                    color: $colorSecondary;

                    p {
                        margin-bottom: 0;
                    }

                    &.max-width {
                        white-space: nowrap;
                        max-width: 175px;

                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    &:hover {
                        color: $colorPrimary;
                    }
                }

                .selected {
                    border-bottom: 4px solid $colorPrimary;
                    color: $colorPrimary;
                }
            }
        }
    }
}

.nav-menu.dark {
    background-color: $colorPrimary;
    height: 50px;
    display: flex;
    align-items: flex-end;

    .navbar-nav .nav-item .menu .nav-link {
        color: white;
        font-size: 16px;

        &.selected {
            border-bottom: 4px solid white;
        }
    }
}

@include media-breakpoint-down(lg) {

    /* version mobile menu standard */

    .nav-menu:not(.dark) {

        .navbar-nav {
            margin: 0;
            width: 100%;
            flex-direction: column;

            .nav-item .menu {
                margin: 0;
                width: 100%;

                .nav-link {
                    display: flex;
                    max-width: 100%;
                    justify-content: space-between;
                    padding: 10px 10px 10px 20px;
                    font-size: 19px;

                    &.selected {
                        border-left: 4px solid $colorPrimary;
                        border-bottom: none;
                    }
                }

            }
        }

        .chevron-right {
            color: $colorSecondary;
            opacity: 0.25;
        }
    }

}

@include media-breakpoint-down(md)  {

    /* version mobile menu dark */

    .nav-menu.dark {
        height: auto;

        .navbar-nav {
            margin: 0;
            height: auto;
            flex-direction: column;

            .nav-item .menu {
                margin-left: 0;

                .nav-link {
                    padding: 10px 10px 10px 20px;

                    &.selected {
                        border-left: 4px solid white;
                        border-bottom: none;
                    }
                }
            }
        }
    }
}




