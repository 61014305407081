
.main-container {
    padding: 0;
    box-shadow: inset 0 20px 10px -10px rgba(56, 96, 165, 0.1);
}

.content-container {
    padding: 20px 40px;

    &.bg-white {
        background: white;
        box-shadow: inset 0 20px 10px -10px rgba(56, 96, 165, 0.1);
    }

    &.bg-white-no-shadow {
        background: white;
    }
}

.content-container-calendar {
    padding: 20px 40px;
    background: white;
}

.stat-container {
    background-color: $colorPrimary;
    padding: 20px;
    box-shadow: 0 15px 20px -4px rgba(56, 96, 165, 0.15);
    height: auto;
    margin-bottom: 50px;
    color: white;

    h4 {
        color: white !important;
    }

    p {
        margin: 0;
        color: white;
    }
}

.bottom-container {
    padding-left: 20px;
    padding-right: 20px;
}

@include media-breakpoint-down(sm) {

    .content-container {
        padding: 20px;
    }
}
