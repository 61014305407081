/* You can add global styles to this file, and also import other style files */
@import 'styles/boostrap-variables';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import "ngx-toastr/toastr";

/* Application styles */
@import "styles/app";
