@media print {
    .app > app-root {
        > .footer {
            display: none;
        }

        app-application-loader {
            display: none;
        }
    }

    #toast-container {
        display: none;
    }
}
