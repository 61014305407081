.modal {

    &.larger {

        .modal-dialog {
            max-width: 575px;
        }
    }
}

.modal-title {
    color: $colorPrimary;
}

.modal-body {
    padding: 10px 25px;
}

.modal-footer.space-between {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    div {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        button {
            margin-left: 10px;
        }
    }
}

// Styling for the custom layout made by :ratio for the mooring places modal
.modal-body.custom-layout {
    padding: 0;

    h6 {
        color: black;
        font-style: italic;
        font-weight: bold;
        margin: 0;
        padding: 20px;
    }

    form {
        background-color: $inputBackgroundColor;

        .form-label {
            margin-top: 0.5em;
        }

        .form-control.margin-bottom {
            margin-bottom: 10px;
        }
    }

    .row {
        background-color: white;
        border-top: 1px solid #dee2e6;
        margin: 0;
        padding: 15px 0;
    }
}
