
table tbody tr {
    --bs-table-striped-bg: rgba(0, 0, 0, 0.02)
}

td {
    background-color: white;
}

.table-wrapper-scroll-y {
    overflow-y: scroll;
    max-height: calc(70vh - 8em);
}

table.no-padding {
    th {
        padding: 0;
    }

    td {
        padding: 0;
    }
}
