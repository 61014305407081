@import "_variables.scss";

ng-multiselect-dropdown {

    .multiselect-dropdown {

        .selected-item {
            margin: 1px;
            max-width: none !important;
        }

        .dropdown-btn {
            border: none !important;
            border-radius: 10px !important;
            background-color: $inputBackgroundColor;
            color: gray;

            .selected-item {
                color: white;
            }

            span {
                font-style: italic;
            }
        }
    }
}
