
.footer {
    padding: 20px 40px;
    display: flex;
    justify-content: center;

    &.bg-white {
        background: white;
        box-shadow: inset 0 20px 10px -10px rgba(56, 96, 165, 0.1);
    }

    a {
        color: $colorSecondary;
        margin: 0 20px;
    }
}
