/*Override bootstrap styles*/

.datatable-body {
    margin-bottom: -5px;
}

.datatable-body-cell-label button:hover {
    cursor: pointer;
}

.datatable-header-cell {
    color: $colorSecondary;
    text-align: left;
    margin-bottom: 30px;
    margin-top: 20px;
    margin-right: 5px;
    padding-right: 30px;

    .resize-handle {
        border-right: 1px solid $colorSecondary;
    }
}

.datatable-row-wrapper {
    border-top: none;
    margin-top: 10px;

    .datatable-body-row {
        border-top: none;
        border-bottom: $greyLineBorder;

        &:not(.unlimited-row-height) {
            height: 50px;
        }

        &.max-150px-row-height {
            height: 150px;
        }
    }

    .datatable-body-cell-label {
        padding-left: 10px;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
            white-space: nowrap;
        }
    }

    option {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

datatable-selection {
    display: inline-block;
    height: 19px;
}

datatable-scroller {
    width: 100% !important;
}

.page-count {
    margin-top: 20px;
    margin-bottom: 20px;
}

.padding-header-cell-filter {
    padding-left: 10px;
}

.margin-header-cell-filter {
    margin-left: -10px !important;
}

.datatable-pager {
    margin-bottom: 20px !important;
}

.pages.active {
    font-weight: bold;
}

.pager {
    li {
        border: 0.55px solid #d6dde3;
        border-radius: 3px;
        background-color: $inputBackgroundColor;
        height: 30px;
        width: 40px;
        font-size: 15px;
        text-align: center;
        padding-top: 5px !important;

        a {
            color: $colorPrimary;
        }
    }
}

.datatable-footer {
    background-color: white;
    overflow: hidden !important;

    .datatable-footer-inner {
        color: $colorPrimary;
        flex-direction: column;
        height: auto !important;
    }
}

