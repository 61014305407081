@import "variables";

@import "buttons";
@import "containers";
@import "datatable";
@import "forms";
@import "footer";
@import "full-calendar";
@import "map";
@import "menu";
@import "modal";
@import "multiselect";
@import "pagination";
@import "print";
@import "ribbon";
@import "tables";
@import "timepicker";

.app {
    background-color: #F5F5F5;
    min-width: 320px;

    &.no-header > app-root > .header {
        display: none;
    }
}

/* HTML Tags */

body {
    font-family: $textFont;
}

h1 {
    color: $colorPrimary;
    font-family: $mainTitleFont;
    font-size: 40px;
    font-weight: 700;
    line-height: 70px;
    text-transform: uppercase;
}

h2 {
    color: $colorPrimary;
    font-family: $subTitleFont;
    font-size: 40px;
    font-weight: 300;
    line-height: 60px;
}

h3 {
    color: $colorSubSubTitle;
    font-family: $subSubTitleFont;
    font-size: 28px;
    font-weight: 600;
    line-height: 30px;
    margin-top: 1em;
}

h4 {
    color: $colorSubSubTitle;
    font-family: $subSubTitleFont;
    line-height: 24px;
    font-size: 24px;
}

h5 {
    color: $colorSubSubTitle;
    font-family: $subSubTitleFont;
    line-height: 22px;
    font-size: 18px;
}

a {
    text-decoration: none !important;
}

pre {
    font-size: revert;
}

/* Custom Classes */

.alert {
    margin-bottom: 0;
}

.alert-notification-bell {
    color: $dotColorRed;
    margin-left: 5px;
}

.align-items-center {
    display: flex;
    align-items: center;
}

.area-link {
    padding: 0;
    height: 100%;
    background-color: transparent;
    white-space: pre-wrap;
    word-break: break-word;
}

.bg-blue {
    background-color: $colorPrimary;
}

.bg-white {
    background-color: $colorPrimary;
}

.dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;

    position: relative;

    &.white-border {
        border: 3px solid white;
    }

    &.gray-border {
        border: 3px solid #F5F5F5;
    }

    &.red {
        background-color: $dotColorRed;
    }

    &.yellow {
        background-color: $dotColorYellow;
    }

    &.green {
        background-color: $dotColorGreen;
    }

    &.gray {
        background-color: $dotColorGray;
    }
}

.has-any-role-hidden {
    display: none !important;
}

.popover {
    /* Display popover under the modal */
    z-index: 1049; /* One less than modal*/
}

.text-pre-wrap {
    white-space: pre-wrap;
}

.text-word-overflow-wrap {
    word-break: normal;
    overflow-wrap: anywhere
}

.text-primary {
    color: $colorPrimary !important;
}

.text-error {
    color: $colorFormError;
}

.overflow-hidden {
    overflow: hidden;
}

@supports (-moz-appearance:none) {
    select {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}
