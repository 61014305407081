
full-calendar {

    a {
        color: $colorPrimary;
        cursor: pointer;
    }

    td.fc-timegrid-slot.fc-timegrid-slot-lane:hover {
        background: #DEEDFF;
        color: white;
    }

    div.fc-timeline-event-harness {
        overflow: hidden;
    }

    .fc {

        .fc-daygrid-day-frame:hover {
            background: #DEEDFF;
            color: white;
        }

        .fc-daygrid-event-harness {
            cursor: pointer;
        }
    }

    .fc-daygrid-event,
    .fc-timegrid-event {
        overflow: hidden;
    }

    .fc-resource .fc-content .fc-datagrid-cell-main fa-icon {
        margin-left: 0.5em;
    }
}

@media screen and (max-width: 767px) {

    full-calendar {

        .fc-view-harness {
            min-height: 400px;
        }

        .fc-col-header-cell {
            overflow: hidden;
        }

        .fc-toolbar.fc-header-toolbar {
            display: flex !important;
            flex-direction: column !important;

            .fc-toolbar-chunk {

                .fc-toolbar-title {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }

                .fc-button-group {

                    .fc-button {
                        width: 100%;
                    }
                }
            }
        }
    }
}
