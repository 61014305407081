@import "_variables.scss";

ngb-timepicker {

    .ngb-tp-input {
        background-color: $inputBackgroundColor;
        border: none;
    }

    &.ng-touched.ng-dirty.ng-invalid{
        border: none;
    }
}
