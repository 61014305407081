/*Override bootstrap styles*/
.pagination {
    justify-content: center;
}

.page-item {
    &.active {
        .page-link {
            background-color: $colorPrimary;
            border-color: $colorPrimary;
        }
    }

    &:not(.active) .page-link {
        color: $colorPrimary;
    }
}
