@import "_variables.scss";

/* Override bootstrap styles */
.btn {
    min-height: 40px;
    border-radius: $borderRadius;
}

.btn-link {
    color: $colorPrimary;
    font-weight: 600;
    text-decoration: none;
}

.btn-primary {
    background-color: $colorPrimary;
    border: none !important;

    &:focus,
    &:active,
    &:disabled,
    &.active,
    &.disabled {
        background-color: $colorPrimary;
    }
}

.btn-outline-secondary {
    border: 2px solid;
}

.btn-outline-danger {
    border: 2px solid;
}

/* Toggle Buttons */

.btn-toggle {
    border-color: $colorPrimary;
    display: flex;
    align-items: center;
}

.btn-toggle:hover {
    background: #004696 !important;
}

.btn-toggle.focus {
    box-shadow: none !important; // remove halo
}

.btn-toggle-left {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.btn-toggle-right {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.btn-toggle-middle {
    border-radius: 0 !important;
}

.btn-toggle:not(:disabled):not(.disabled):hover,
.btn-toggle:not(:disabled):not(.disabled).active,
.show > .btn-toggle.dropdown-toggle {
    color: #fff;
    background-color: $colorPrimary;
    border-color: $colorPrimary;
}

/* Button Bars */

.btn-bar {
    // @extend .row;
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    margin: 20px 0;
    width: 100%;

    .left-bar {
        display: flex;
        gap: 15px;
        justify-content: flex-start;
    }

    .right-bar {
        display: flex;
        gap: 15px;
        justify-content: flex-end;
    }
}

@include media-breakpoint-down(sm) {

    .btn-bar {
        justify-content: center;
        flex-direction: column;

        .btn {
            margin-top: 10px;
            margin-left: 0;
        }
    }
}
