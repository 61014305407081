@import "_variables.scss";

input.ng-touched.ng-invalid,
input.ng-dirty.ng-invalid,
select.ng-touched.ng-invalid,
select.ng-dirty.ng-invalid,
textarea.ng-touched.ng-invalid,
textarea.ng-dirty.ng-invalid,
ngb-timepicker.ng-touched.ng-dirty.ng-invalid{
  border: 1px solid red;
}

input:disabled,
textarea:disabled {
    background-color: transparent;
}

label {
    color: $colorSecondary;
}

pre {
    font-family: $textFont;
}

select {
    max-width: 100%;
}

.btn-toggle {

    &:disabled, &.disabled {
        &.active {
            color: #fff;
            background-color: $colorPrimary;
            border-color: $colorPrimary;
        }
        &:not(.active):hover{
            background: none !important;
        }
    }
}

.default-filter-form-field {
    @extend .col-12 !optional;
    @extend .col-lg-6 !optional;
    @extend .col-xl-4 !optional;
}

.form-control,
.form-select {
    background-color: $inputBackgroundColor;
    border: none;
    border-radius: $borderRadius;
    color: $colorInputText;

    &:not(textarea) {
        height: 40px;
    }

    &::placeholder {
        color: gray;
        font-style: italic;
    }

}

.form-label {
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.form-check {
    padding-left: 3.5em;
}

.input-group {

    .form-control {
        border-radius: $borderRadius !important;
    }
}

.input-group-text {
    background: transparent;
    border: none;
    height: 40px;
    width: 50px;
}

app-date-range-picker {

    .md-drppicker {
        .buttons {
            .buttons_input {
                .btn {
                    background-color: $colorPrimary;
                }
            }
        }

        .calendar-table {
            .start-date {
                background-color: $colorPrimary;
            }

            .end-date {
                background-color: $colorPrimary;
            }
        }
    }

}
