@import "_variables.scss";

.coord {
    cursor: pointer;
    fill: black;
    text-anchor: middle;

    &.bold {
        font-weight: bolder;
    }

    &.orange {
        fill: orange;
    }

    &.grey {
        fill: grey;
    }

    &.red {
        fill: red;
    }
}

.map-container {
    border: 1px dashed lightgray;
    position: relative;

    .spinner-container {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
    }

    .btn-container {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 10;
    }

    .btn-group {

        button {
            font-size: 24px;
            line-height: 24px;
            width: 40px;
        }

        #zoom-in {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        #zoom-out {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}
