
.ribbon {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
    z-index: 10;

    .bg-qa {
        background-color: blue;
    }

    .bg-local {
        background-color: red;
    }

    .bg-dev {
        background-color: green;
    }
}
.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid $colorPrimary;
}
.ribbon span {
    position: absolute;
    display: block;
    height: 50px !important;
    width: 130px;
    padding: 15px 0;
    background-color: $colorPrimary;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 15px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
}

/* top left*/
.ribbon-top-left {
    top: -10px;
    left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}
.ribbon-top-left::before {
    top: 0;
    right: 0;
}
.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}
.ribbon-top-left span {
    right: 6px;
    top: 12px;
    transform: rotate(-45deg);
}
