@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/variables";

@font-face {
    font-family: "SohoGothicPro";
    src: url("../font/SohoGothicPro-Regular.woff2") format("woff2"), /* Chrome, Firefox */
    url("../font/SohoGothicPro-Regular.woff") format("woff"), /* Chrome, Firefox */
    url('../font/SohoGothicPro-Regular.ttf') format("truetype"); /* Chrome, Firefox, Opera, Safari, Android, iOS */
}

@font-face {
    font-family: "GothamHTFBook";
    src: url("../font/GothamHTFBook.woff2") format("woff2"), /* Chrome, Firefox */
    url("../font/GothamHTFBook.woff") format("woff"), /* Chrome, Firefox */
    url('../font/GothamHTFBook.ttf') format("truetype"); /* Chrome, Firefox, Opera, Safari, Android, iOS */
}

$mainTitleFont: "SohoGothicProBold", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$subTitleFont: "GothamHTFBooklight", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$subSubTitleFont: "GothamHTFBooklight", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
$textFont: "GothamHTFBooklight", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;

$colorPrimary: #003A7C;
$colorPrimaryLight: #498CD9;
$colorSecondary: #6F7D8C;
$colorLink: $colorPrimary;
$colorSubSubTitle: #212529;

$dotColorRed: red;
$dotColorYellow: #FFD700;
$dotColorGreen: green;
$dotColorGray: gray;

$colorFormError: #dc3545;

$colorTableCellHover: #424242;

$colorSearchBarBorder: $colorSecondary;
$colorSearchBarShadow: $colorPrimary;
$colorInputText: #212529;

$borderRadius: 10px;

$greyLineBorder: 1px solid rgba(155, 177, 200, .2);
$marginTopContainerContent: 40px;
$marginSideContainerContent: 20px;
$inputBackgroundColor: #f9f9f9;

/* PROD theme color */
$navbarBackground: $colorSecondary;
$navbarFontColor: white;

/* DEV Navbar */
$devNavbarBackground: #a5a3a3; /* light gray */
$devNavbarFontColor: white;

/* Azure Navbar */
$azureNavbarBackground: #4CA755; /* light green */
$azureNavbarFontColor: white;

/* Int Navbar */
$intNavbarBackground: #33A0CC; /* light blue */
$intNavbarFontColor: white;
